import { forwardRef, memo, useMemo } from 'react';
import type { DividerSlotsAndSlotProps } from '@mui/joy/Divider';
import BaseDivider from '@mui/joy/Divider';
import { slowCn } from '../../lib/utils';
import type { DividerProps } from './types';

const colorVariableMap = {
  'border-brand': 'var(--border-brand)',
  'border-brand-solid': 'var(--border-brand-solid)',
  'border-brand-solid_alt': 'var(--border-brand-solid_alt)',
  'border-primary': 'var(--border-primary)',
  'border-secondary': 'var(--border-secondary)',
  'border-tertiary': 'var(--border-tertiary)',
  'border-success': 'var(--border-success)',
  'border-warning': 'var(--border-warning)',
  'border-error': 'var(--border-error)',
  'fg-quinary': 'var(--fg-quinary)',
};

const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => {
  const { className, color = 'border-secondary', style, ...rest } = props;

  const colorVariableInClassName = useMemo(() => {
    return Object.keys(colorVariableMap).find((colorVariable) =>
      className?.includes(colorVariable),
    ) as DividerProps['color'];
  }, [className]);

  const dividerSlotProps = useMemo<DividerSlotsAndSlotProps['slotProps']>(
    () => ({
      root: {
        className: slowCn(
          'h-[unset]',
          className,
          props.children ? 'text-secondary' : 'text-transparent',
        ),
        style: {
          ...style,
          '--Divider-lineColor': colorVariableMap[colorVariableInClassName ?? color],
        },
      },
    }),
    [className, color, colorVariableInClassName, props.children, style],
  );

  return <BaseDivider ref={ref} slotProps={dividerSlotProps} {...rest} />;
});

Divider.displayName = 'Divider';

export default memo(Divider);
